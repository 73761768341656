/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import { formatDistanceToNow } from 'date-fns'
import { cloneDeep, findLast, get, lowerCase, map, reverse, set } from 'lodash'
import React from 'react'

import { BrandingHeader } from '../../../components/branding-header'
import { moveWorkflowStatusWithVersions } from '../../../components/feature-flags'
import Spinner from '../../../components/spinner'
import Tooltip, { TooltipTrigger } from '../../../components/tooltip'
import UserTypeahead from '../../../flowbot/components/user-typeahead'
import * as Icons from '../../../icons'
import { useAlerts } from '../../../ui/alerts'
import Button from '../../../ui/button'
import { Space } from '../../../ui/layout'
import { Popover2 } from '../../../ui/popover'
import WithCommentContent from '../with-comment-content'
import Box from './box'
import Comment from './comment'
import { ReactComponent as PenIcon } from './pen-write.svg.jsx'
import * as Status from './status'
import {
  updateSimulationInCache,
  useRefreshSimulation
} from './use-refresh-simulation'
import { useWorkflowOverview } from './utils'

export const DocumentQueryContext = React.createContext({ query: null })

export default function WorkflowTracker ({
  hasWFTroubleshootPerms,
  simulation,
  documentQuery,
  documentId,
  appId,
  workflowResends,
  hideComments,
  ...props
}) {
  const [workflowOverview, updateWorkflowOverview] =
    useWorkflowOverview(simulation)
  const [refreshSimulation, refreshError] =
    useRefreshSimulation(DocumentQueryContext)
  const [refreshing, setRefreshing] = React.useState(false)
  const alerts = useAlerts()
  if (refreshError) {
    alerts.type3(refreshError, 'error')
  }
  if (!workflowOverview) {
    return (
      <div className='text-center lg:mt-40'>
        <Box
          title={i18n._('pagesrunner.workflow.history.unavailable')}
          data-testid='workflow-tracker'
        />
      </div>
    )
  }
  const isComplete = ['Complete', 'Withdrawn', 'Denied'].includes(
    workflowOverview.status
  )
  const MainElement = (
    <Box
      title={i18n._('pagesrunner.workflow.status')}
      data-testid='workflow-tracker'
      refreshing={refreshing}
      refreshSimulation={
        hasWFTroubleshootPerms &&
        !isComplete &&
        (async () => {
          try {
            setRefreshing(true)
            await refreshSimulation(appId, documentId, updateWorkflowOverview)
            alerts.type3(i18n._('pagesrunner.workflow.updated'), 'info')
          } catch (error) {
            alerts.type3(error, 'error')
          } finally {
            setRefreshing(false)
          }
        })
      }
    >
      {getWorkflowSteps(workflowOverview, {
        appId,
        documentId,
        hasWFTroubleshootPerms,
        hideComments,
        updateWorkflowOverview,
        workflowResends,
        ...props
      })}
    </Box>
  )
  return documentQuery ? (
    <DocumentQueryContext.Provider value={{ query: documentQuery }}>
      {props.branding && <BrandingHeader branding={props.branding} />}
      {MainElement}
    </DocumentQueryContext.Provider>
  ) : (
    MainElement
  )
}

export function getWorkflowSteps (
  workflowOverview,
  {
    appId,
    expandOrCollapseAll,
    showFutureSteps = true,
    updateWorkflowOverview,
    ...props
  }
) {
  if (moveWorkflowStatusWithVersions) {
    const steps =
      workflowOverview.stepsWithAllVersions || workflowOverview.steps
    return (
      <ul className='my-4'>
        {map(reverse([...steps]), (step, i) =>
          shouldHideStep(step, showFutureSteps) ? null : (
            <StepOverview
              allSteps={steps}
              applicationId={appId}
              expandOrCollapseAll={expandOrCollapseAll}
              key={step?.id}
              step={step}
              updateOverview={updateWorkflowOverview}
              {...props}
            />
          )
        )}
      </ul>
    )
  }
  return map(workflowOverview.steps, (step, i) =>
    shouldHideStep(step, showFutureSteps) ? null : (
      <>
        <div
          id='old-way'
          className='mb-2 border-b border-light-gray-400 p-4'
          key={`${step?.name}-${i}`}
          data-testid='workflow-tracker-step'
          tabIndex={0}
        >
          <StepHeader allSteps={workflowOverview.steps} step={step} />
          <StepDetailsOld
            allSteps={workflowOverview.steps}
            applicationId={appId}
            step={step}
            updateOverview={updateWorkflowOverview}
            {...props}
          />
        </div>
      </>
    )
  )
}

function shouldHideStep (step, showFutureSteps) {
  return (
    step.users?.find(u => u.id === 'repair-util') ||
    (!showFutureSteps && step.simulated) ||
    (!moveWorkflowStatusWithVersions && step.type === 'workflowComplete')
  )
}

function ErrorMsg ({ children }) {
  return <div className='my-3 flex items-center text-red-400'>{children}</div>
}

function ToolTipContent ({ children }) {
  return <div className='w-40'>{children}</div>
}

/* New UI */
const StepOverview = ({ step, allSteps, expandOrCollapseAll, ...props }) => {
  const ref = React.useRef()
  const isInProgress = !step.completedAt && !step.simulated
  React.useEffect(() => {
    if (isInProgress && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [])
  const isComplete = step.completedAt
  const [showDetails, setShowDetails] = React.useState(!isComplete)
  React.useEffect(() => {
    if (expandOrCollapseAll === 'expand') {
      setShowDetails(true)
    } else if (expandOrCollapseAll === 'collapse') {
      setShowDetails(false)
    }
  }, [expandOrCollapseAll])
  const hasError = get(step, 'error', false)
  const details = StepDetails({ step, allSteps, hasError, ...props })
  const bottomBorder = isInProgress
    ? 'border-b-[6px] border-orange-300'
    : 'border-b border-light-gray-300'

  const backgroundColor =
    step?.type === 'workflowComplete'
      ? step?.status === 'Workflow Rejected'
        ? 'bg-red-50'
        : 'bg-green-50'
      : ''
  return (
    <li
      ref={ref}
      className={cx('p-4', bottomBorder, backgroundColor)}
      id={step?.id}
    >
      <div className='flex items-center justify-between'>
        <StepIcon step={step} />
        <div>
          <SuperTitle step={step} />
          <StepTitle step={step} />
          <SubTitle step={step} />
        </div>
        <div className='flex-grow' />
        {details && (
          <div className='print:hidden'>
            <button
              className='kp-button-transparent kp-button-icon kp-button-lg m-0 h-4 p-0 text-medium-gray-500 dark:text-medium-gray-300'
              onClick={() => setShowDetails(!showDetails)}
              aria-label={
                showDetails
                  ? i18n._('workflow.less.details')
                  : i18n._('workflow.more.details')
              }
            >
              {showDetails ? (
                <Icons.SelectDownArrow />
              ) : (
                <Icons.SelectUpArrow />
              )}
            </button>
          </div>
        )}
      </div>
      {showDetails && details}
      {isInProgress && (
        <div className='relative left-4 top-7 w-fit rounded-sm bg-orange-400 px-2 py-[2px] text-[9px] font-medium uppercase text-dark-gray-500'>
          <Trans id='pagesrunner.action.workflow.you.are.here' />
        </div>
      )}
    </li>
  )
}
const STATUS_ICONS = {
  Cancelled: {
    icon: <Icons.WorkflowSkipped className='fill-medium-gray-500' />,
    bgColor: 'bg-light-gray-300'
  },
  Error: {
    icon: <Icons.AlertError className='fill-red-500' />,
    bgColor: 'bg-red-100'
  },
  Reassigned: {
    icon: <Icons.WorkflowReassigned className='fill-orange-500' />,
    bgColor: 'bg-orange-100'
  },
  Rejected: {
    icon: <Icons.WorkflowX className='fill-red-500' />,
    bgColor: 'bg-red-100'
  },
  Retried: {
    icon: <Icons.WorkflowRetry className='fill-orange-500' />,
    bgColor: 'bg-orange-100'
  },
  'Sent Back': {
    icon: <Icons.WorkflowBack className='fill-orange-500' />,
    bgColor: 'bg-orange-100'
  },
  Skipped: {
    icon: <Icons.WorkflowSkipped className='fill-orange-500' />,
    bgColor: 'bg-orange-100'
  },
  'Workflow Complete': {
    icon: <Icons.WorkflowComplete className='fill-green-500' />,
    bgColor: 'bg-green-100'
  },
  'Workflow Rejected': {
    icon: <Icons.WorkflowComplete className='fill-red-500' />,
    bgColor: 'bg-red-100'
  }
}

const StepIcon = ({ step }) => {
  const defaultFill = step.completedAt
    ? 'fill-green-500'
    : 'fill-medium-gray-500'
  const statusIcon = STATUS_ICONS[step?.status]
  let icon, bgColor
  if (statusIcon) {
    icon = statusIcon.icon
    bgColor = statusIcon.bgColor
  } else {
    if (step.completedAt) {
      bgColor = 'bg-green-100'
    } else {
      bgColor = 'bg-light-gray-300'
    }
    switch (step?.type) {
      case 'formfill':
        icon = <Icons.Clipboard className={defaultFill} />
        break
      case 'acknowledge':
        icon = <Icons.Like className={defaultFill} />
        break
      case 'approval':
        icon = <Icons.WorkflowCheck className={defaultFill} />
        break
      case 'task':
        icon = <Icons.Point className={defaultFill} />
        break
      case 'notification':
        icon = <Icons.Email className={defaultFill} />
        break
      case 'integration':
        icon = <Icons.Actions className={defaultFill} />
        break
      default:
        icon = null
    }
  }
  return (
    <span
      className={cx(
        'mr-4 flex h-8 w-8 items-center justify-center rounded-full',
        bgColor
      )}
    >
      {icon}
    </span>
  )
}
const SuperTitle = ({ step }) => {
  const dateString = step.completedAt ? (
    formatDate(step.completedAt)
  ) : step.simulated ? (
    <Trans id='future' />
  ) : (
    <Trans id='in.progress' />
  )
  return (
    <div className='text-sm text-medium-gray-500'>
      {dateString}
      {step.versionNumber ? ` - V${step.versionNumber}` : ''}
    </div>
  )
}
const StepTitle = ({ step }) => {
  let color
  let title = step?.msg
  switch (step?.status) {
    case 'Error':
      color = 'text-red-400'
      break
    case 'Retried':
      color = 'text-medium-gray-500'
      title = 'Retried'
      break
    default:
      color = 'text-medium-gray-500'
  }
  return (
    <div className={cx('text-base font-medium', color)}>
      {translateTitle(title, step?.type)}
    </div>
  )
}
const translateTitle = (title, stepType) => {
  switch (title) {
    case 'Submitted for Approval':
      return i18n._('pagesrunner.workflow.submitted.for.approval')
    case 'Task Completed':
      return i18n._('pagesrunner.workflow.task.completed')
    case 'Acknowledged':
      return i18n._('pagesrunner.action.workflow.actions.acknowledged')
    case 'Approved':
      return i18n._('pagesrunner.action.workflow.actions.approved')
    case 'Denied':
      return i18n._('pagesrunner.action.workflow.actions.rejected')
    case 'Notification Sent':
      return i18n._('pagesrunner.action.workflow.actions.notified')
    case 'Completed':
      if (stepType === 'integration') {
        return i18n._(
          'pagesrunner.action.workflow.actions.integration.completed'
        )
      }
      return i18n._('pagesrunner.action.workflow.actions.completed')
    case 'Skipped':
      return i18n._('pagesrunner.action.workflow.actions.skipped')
    case 'Retried':
      return i18n._('pagesrunner.action.workflow.actions.retried')
    case 'Sent Back':
      return i18n._('pagesrunner.action.sent.back')
    case 'Workflow Rejected':
      return i18n._('pagesrunner.workflow.rejected')
    case 'Workflow Complete':
      return i18n._('pagesrunner.workflow.complete')
    default: {
      const tokens = title.split(' ')
      if (tokens.length === 2) {
        if (tokens[1] === 'Cancelled') {
          return i18n._('pagesrunner.workflow.step.cancelled', {
            type: tokens[0]
          })
        } else if (tokens[1] === 'Error') {
          return i18n._('pagesrunner.workflow.step.error', {
            type: tokens[0]
          })
        }
      } else if (tokens.length > 2) {
        if (tokens[0] === 'Waiting' && tokens[1] === 'for') {
          return i18n._('pagesrunner.workflow.waiting.for.action', {
            action: tokens.slice(2).join(' ')
          })
        }
      }
      return title
    }
  }
}
const SubTitle = ({ step }) => {
  const subtitle = step?.name || step?.stepName
  return !subtitle || step?.type === 'formfill' ? null : (
    <div className='text-xs text-medium-gray-500'>({subtitle})</div>
  )
}

const StepDetails = ({ step, allSteps, ...props }) => {
  const {
    applicationId,
    documentId,
    hasError,
    hasWFTroubleshootPerms,
    hideComments,
    updateOverview,
    workflowResends
  } = props
  if (step.type === 'workflowComplete') return
  const users = get(step, 'group.users', step?.users)
  const actions = UserActions({
    futureStep: step.simulated,
    group: step?.group,
    hasError,
    hideComments,
    stepType: step?.type,
    users
  })
  const signatures = get(step, 'signatures', [])
  const signatureInfo = SignatureInfo({ signatures })
  const duplicateActions = get(step, 'duplicateActions', [])
  const showTroubleshootButtons =
    hasWFTroubleshootPerms && step.type !== 'formfill'
  const troubleshootButtons = showTroubleshootButtons && (
    <TroubleshootButtons
      applicationId={applicationId}
      documentId={documentId}
      step={step}
      updateOverview={updateOverview}
      workflowResends={workflowResends}
      {...props}
    />
  )
  const errorInfo = hasError ? (
    <div className='mb-4 text-sm font-normal text-medium-gray-500'>
      {step.error}
    </div>
  ) : null

  const duplicateInfo = duplicateActions.length ? (
    <DuplicateInfo duplicateActions={duplicateActions} />
  ) : null

  return errorInfo ||
    signatureInfo ||
    duplicateInfo ||
    actions ||
    troubleshootButtons ? (
    <div className='ml-12 mt-4'>
      {errorInfo}
      {signatureInfo}
      {duplicateInfo}
      {actions}
      {troubleshootButtons}
    </div>
  ) : null
}

const UserActions = ({ futureStep, group, hideComments, stepType, users }) => {
  if (!users.length) {
    return null
  }
  return (
    <>
      {group?.name && stepType !== 'formfill' && (
        <div className='mb-2 mt-2 text-xs font-bold'>
          {group.name} - {group.role}
        </div>
      )}
      <ul
        aria-label={i18n._('pagesrunner.action.workflow.tracker.user.actions')}
        className='mb-4'
      >
        {users.map((user, i) => {
          const comment = get(user, 'workflowAction.comment')
          const date = formatDate(get(user, 'workflowAction.date'))
          const action = get(user, 'workflowAction.action')
          return (
            <li className='my-4' key={`${get(user, 'id')}-${i}`}>
              <>
                <div className='flex items-center font-medium text-medium-gray-500'>
                  <Status.Icon status={action} />
                  <div>
                    {!futureStep && (
                      <div className='text-xs'>
                        <ActionDetails
                          action={action}
                          date={date}
                          stepType={stepType}
                        />
                      </div>
                    )}
                    <div className='text-sm'>
                      {user?.displayName || user?.email}
                    </div>
                  </div>
                </div>
                {comment && !hideComments && (
                  <Comment className='mb-2 text-sm'>{comment}</Comment>
                )}
              </>
            </li>
          )
        })}
      </ul>
    </>
  )
}
const SignatureInfo = ({ signatures }) => {
  if (signatures.length) {
    return (
      <ul className='mb-2'>
        {signatures.map((signature, i) => {
          const date = formatDate(signature.date)
          return (
            <li
              className='flex items-center font-medium text-medium-gray-500'
              key={`signature-${i}`}
            >
              <Icons.PenDraw className='mr-[7px] mt-1 fill-blue-500' />
              <div>
                <div className='text-xs'>
                  <Trans id='pagesrunner.action.workflow.actions.signed' />
                  {' - '}
                  {date}
                </div>
                <div className='text-sm'>{signature.displayName}</div>
              </div>
            </li>
          )
        })}
      </ul>
    )
  } else {
    return null
  }
}

const ActionDetails = ({ action, date, stepType }) => {
  if (!action) return null
  switch (action) {
    case 'pending':
      return getPendingStatus(stepType)
    case 'cancelled':
      return null
    default:
      return `${getActionWord(action)} - ${date}`
  }
}

const getPendingStatus = stepType => {
  switch (stepType) {
    case 'formfill':
      return i18n._('pagesrunner.workflow.waiting.for.submission')
    case 'acknowledge':
      return i18n._('pagesrunner.workflow.waiting.for.review')
    case 'approval':
      return i18n._('pagesrunner.workflow.waiting.for.approval')
    case 'task':
      return i18n._('pagesrunner.workflow.waiting.for.task')
    default:
      return i18n._('pagesrunner.workflow.pending')
  }
}

const getActionWord = (action, to) => {
  switch (lowerCase(action)) {
    case 'skipped':
      return i18n._('pagesrunner.action.workflow.actions.skipped')
    case 'acknowledged':
      return i18n._('pagesrunner.action.workflow.actions.acknowledged')
    case 'approved':
      return i18n._('pagesrunner.action.workflow.actions.approved')
    case 'rejected':
      return i18n._('pagesrunner.action.workflow.actions.rejected')
    case 'retried':
      return i18n._('pagesrunner.action.workflow.actions.retried')
    case 'reassigned':
      return i18n._('pagesrunner.action.workflow.actions.reassigned', { to })
    case 'completed':
      return i18n._('pagesrunner.action.workflow.actions.submitted')
    case 'sent back':
      return i18n._('pagesrunner.action.workflow.actions.sent.back')
    default:
      return i18n._('pagesrunner.action.workflow.actions.completed')
  }
}

/* Old UI */

const StepHeader = ({ step, allSteps }) => {
  const reassignedFrom = get(step, 'reassignedFrom')
    ? allSteps.find(s => s.id === step.reassignedFrom)
    : undefined
  const reassignedGroupName = reassignedFrom?.group?.name
  return (
    <div>
      <div className='mb-2 text-base font-bold text-dark-gray-300'>
        {get(step, 'name', get(step, 'stepName'))}
        {get(step, 'group.role') && ` - ${get(step, 'group.name')}`}
        {reassignedGroupName && ` - ${reassignedGroupName}`}
        {reassignedFrom && ' (Reassigned)'}
        {get(step, 'isRetry') && ' (Retry)'}
        {get(step, 'status') === 'Skipped' && ' (Skipped)'}
      </div>
      <div className='font-medium text-medium-gray-500'>
        <Status.Message status={get(step, 'visualStatus')}>
          {get(step, 'msg')}
        </Status.Message>
        {get(step, 'group.role') && get(step, 'group.name') && (
          <span>
            <span className='px-1 after:content-["|"]' />
            {get(step, 'group.role')}
          </span>
        )}
      </div>
    </div>
  )
}

function formatDate (rawDate) {
  if (!rawDate) return null
  const date = new Intl.DateTimeFormat(window.navigator.language, {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  }).format(+new Date(rawDate))
  const time = new Intl.DateTimeFormat(window.navigator.language, {
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h12',
    timeZoneName: 'short'
  })
    .format(+new Date(rawDate))
    .replace(' AM', 'am')
    .replace(' PM', 'pm')
  return `${date} - ${time}`
}

function updateResendsInCache (cache, query, resends) {
  if (!query || !resends) return
  const rawData = cache.readQuery(query)
  const documentResults = cloneDeep(rawData)
  const cacheKey = documentResults?.action
    ? 'action.document.meta.workflowResends'
    : 'document.meta.workflowResends'
  set(documentResults, cacheKey, resends)
  cache.writeQuery({ ...query, data: documentResults })
}

const retryMutation = gql`
  mutation RetryStep($args: RetryWorkflowStepsInput!) {
    retryWorkflowStep(args: $args)
  }
`

const useRetryStep = () => {
  const { query } = React.useContext(DocumentQueryContext)
  const [retryStep, { error }] = useMutation(retryMutation, {
    update (cache, { data }) {
      updateSimulationInCache(cache, query, data.retryWorkflowStep)
    }
  })
  const retry = async ({ appId, documentId, id }, updateOverview) => {
    const variables = { args: { appId, documentId, stepId: id } }
    const resp = await retryStep({ variables })
    updateOverview({ simulation: resp.data.retryWorkflowStep })
  }
  return [retry, error]
}

const skipMutation = gql`
  mutation SkipStep($args: SkipWorkflowStepsInput!) {
    skipWorkflowStep(args: $args)
  }
`

const useSkipStep = () => {
  const { query } = React.useContext(DocumentQueryContext)
  const [skipStep, { error }] = useMutation(skipMutation, {
    update (cache, { data }) {
      updateSimulationInCache(cache, query, data.skipWorkflowStep)
    },
    refetchQueries: query?.query?.definitions[0]?.name?.value
      ? [query?.query?.definitions[0]?.name?.value]
      : []
  })

  const skip = async (
    { appId, documentId, id: stepId, firstActionId, actionId },
    comment,
    updateOverview
  ) => {
    const variables = {
      args: { appId, documentId, stepId, variables: { comment } }
    }
    const resp = await skipStep({ variables })
    updateOverview({ simulation: resp.data.skipWorkflowStep })
  }

  return [skip, error]
}

const reassignMutation = gql`
  mutation ReassignStep($args: ReassignWorkflowStepsInput!) {
    reassignWorkflowStep(args: $args)
  }
`

const useReassignStep = () => {
  const { query } = React.useContext(DocumentQueryContext)
  const [reassignStep, { error }] = useMutation(reassignMutation, {
    update (cache, { data }) {
      updateSimulationInCache(cache, query, data.reassignWorkflowStep)
    }
  })

  const reassign = async (
    { appId, documentId, id: stepId },
    comment,
    user,
    updateOverview
  ) => {
    const variables = {
      args: { appId, documentId, stepId, variables: { comment, user } }
    }
    const resp = await reassignStep({ variables })
    updateOverview({ simulation: resp.data.reassignWorkflowStep })
  }

  return [reassign, error]
}

const resendMutation = gql`
  mutation ResendApprovalNotification(
    $args: ResendApprovalNotificationsInput!
  ) {
    resendApprovalNotifications(args: $args)
  }
`
const useResendApprovalNotification = () => {
  const { query } = React.useContext(DocumentQueryContext)
  const [resend, { error }] = useMutation(resendMutation, {
    update (cache, { data }) {
      updateResendsInCache(cache, query, data.resendApprovalNotifications)
    }
  })
  const resendApproval = async (
    { appId, documentId, id: stepId },
    updateOverview
  ) => {
    await resend({
      variables: { args: { appId, documentId, stepId } }
    })
  }
  return [resendApproval, error]
}

const StepDetailsOld = ({
  allSteps,
  applicationId,
  documentId,
  hasWFTroubleshootPerms,
  step,
  updateOverview,
  workflowResends,
  newActionList,
  hideComments,
  ...props
}) => {
  const users = get(step, 'group.users', step.users)
  const signatures = get(step, 'signatures', [])
  const hasError = get(step, 'error', false)
  const duplicateActions = get(step, 'duplicateActions', [])
  const showTroubleshootButtons =
    !hasError &&
    hasWFTroubleshootPerms &&
    !newActionList &&
    step.type !== 'formfill'
  return (
    <div className='py-3'>
      {hasError && (
        <ErrorDetailsOld
          applicationId={applicationId}
          documentId={documentId}
          hasWFTroubleshootPerms={hasWFTroubleshootPerms}
          step={step}
          updateOverview={updateOverview}
          {...props}
        />
      )}
      <UserActionsOld
        hasError={hasError}
        users={users}
        hideComments={hideComments}
      />
      <SignatureInfoOld signatures={signatures} />
      <DuplicateInfo duplicateActions={duplicateActions} />
      {showTroubleshootButtons && (
        <TroubleshootButtons
          applicationId={applicationId}
          documentId={documentId}
          step={step}
          updateOverview={updateOverview}
          workflowResends={workflowResends}
          {...props}
        />
      )}
    </div>
  )
}

const ErrorDetailsOld = ({
  applicationId,
  documentId,
  hasWFTroubleshootPerms,
  step,
  updateOverview,
  ...props
}) => {
  return (
    <div>
      <ErrorMsg>
        <Icons.AlertError className='fill-red-400' mr={2} />
        <div>
          {step.simulated ? (
            <Trans id='pagesrunner.workflow.will.fail' />
          ) : (
            <Trans id='pagesrunner.workflow.has.failed' />
          )}
        </div>
      </ErrorMsg>
      <div>{step.error}</div>
      {hasWFTroubleshootPerms && (
        <TroubleshootButtons
          applicationId={applicationId}
          documentId={documentId}
          hasError
          step={step}
          updateOverview={updateOverview}
          {...props}
        />
      )}
    </div>
  )
}

const minutesSinceExecution = executionTimeString => {
  const diff = new Date() - new Date(executionTimeString)
  return diff / 1000 / 60 // Milliseconds to Seconds
}

const TroubleshootButtons = ({
  applicationId,
  documentId,
  hasError = false,
  step,
  updateOverview,
  workflowResends,
  dataSetId,
  isTable,
  firstPageId,
  firstActionId,
  actionId,
  ...props
}) => {
  const alerts = useAlerts()
  const [processing, setProcessing] = React.useState(false)
  const [retryStep, retryStepError] = useRetryStep()
  const [skipStep, skipStepError] = useSkipStep()
  const [reassignStep, reassignStepError] = useReassignStep()
  const [resendApproval, resendApprovalError] = useResendApprovalNotification()
  const inProgress =
    !step.simulated &&
    !step.completedAt &&
    (step.type !== 'acknowledge' || hasError)
  const isIntegration = step.type === 'integration'
  const canRetryNotification =
    step.type === 'notification' &&
    (step.status === 'Generating PDF' ||
      minutesSinceExecution(step.startedAt) > 9)
  const canResendApprovalEmailOrReassign =
    // form-approval is old workflow engine
    (step.type === 'approval' ||
      step.type === 'form-approval' ||
      step.type === 'task') &&
    inProgress
  const timeSinceLastResend = calculateLastResentTime(step, workflowResends)
  const tableUrl = `/app/${applicationId}/page/${firstPageId}/workflow/general-data/${dataSetId}/workflow`
  const productUrl = `/app/${applicationId}/page/${dataSetId}/workflow`
  const appUrl = `/workflow/${applicationId}`
  const trueUrl = isTable ? tableUrl : dataSetId ? productUrl : appUrl
  return (
    inProgress && (
      <div className='troubleshoot-buttons mt-2 flex flex-wrap align-middle'>
        {(hasError || isIntegration || canRetryNotification) && (
          <TooltipTrigger
            as={Button}
            small
            mb={2}
            mr={2}
            data-testid='step-retry'
            tooltipId='retry-tooltip'
            disabled={processing}
            onClick={async () => {
              if (!processing) {
                setProcessing('retry')
                try {
                  await retryStep(
                    { ...step, appId: applicationId, documentId },
                    updateOverview
                  )
                } catch (error) {
                  alerts.type3(error, 'error')
                } finally {
                  setProcessing(false)
                }
              }
            }}
          >
            {processing === 'retry' ? <Spinner size={20} /> : 'Retry'}
          </TooltipTrigger>
        )}
        <Tooltip id='retry-tooltip' place='bottom'>
          <ToolTipContent>
            <Trans id='pagesrunner.workflow.restart.step' />
          </ToolTipContent>
        </Tooltip>
        <Popover2
          role='dialog'
          trigger={
            <TooltipTrigger
              as={Button}
              small
              mb={2}
              mr={2}
              data-testid='step-skip'
              tooltipId='skip-tooltip'
              disabled={processing}
            >
              <Trans id='pagesrunner.workflow.skip' />
            </TooltipTrigger>
          }
        >
          {hide => (
            <WithCommentContent
              required
              label={i18n._('pagesrunner.workflow.skip')}
              submitting={processing}
              onCancel={hide}
              onClick={async ({ comment }) => {
                if (!processing) {
                  setProcessing('skip')
                  try {
                    await skipStep(
                      {
                        ...step,
                        appId: applicationId,
                        documentId,
                        firstActionId,
                        actionId
                      },
                      comment,
                      updateOverview
                    )
                  } catch (error) {
                    alerts.type3(error.message, 'error')
                  } finally {
                    setProcessing(false)
                  }
                }
              }}
            />
          )}
        </Popover2>
        <Tooltip id='skip-tooltip' place='bottom'>
          <ToolTipContent>
            <Trans id='pagesrunner.workflow.skip.step' />
          </ToolTipContent>
        </Tooltip>
        {canResendApprovalEmailOrReassign && (
          <Popover2
            role='dialog'
            trigger={
              <TooltipTrigger
                as={Button}
                small
                mr={2}
                data-testid='step-reassign'
                tooltipId='reassign-tooltip'
                disabled={processing}
              >
                <Trans id='pagesrunner.workflow.reassign' />
              </TooltipTrigger>
            }
          >
            {hide => (
              <WithCommentContent
                required={['user', 'comment']}
                label={i18n._('pagesrunner.workflow.reassign')}
                submitting={processing}
                onCancel={hide}
                onClick={async ({ comment, user }) => {
                  if (!processing) {
                    setProcessing('reassign')
                    try {
                      await reassignStep(
                        {
                          ...step,
                          appId: applicationId,
                          documentId
                        },
                        comment,
                        user,
                        updateOverview
                      )
                    } catch (error) {
                      alerts.type3(error, 'error')
                    } finally {
                      setProcessing(false)
                    }
                  }
                }}
              >
                {(data, setData) => (
                  <>
                    <label
                      className='block text-sm text-medium-gray-500 after:ml-1 after:text-red-400 after:content-["*"]'
                      id='user-label'
                      htmlFor='user-picker'
                      required
                    >
                      <Trans id='pagesrunner.workflow.reassign.step' />
                    </label>
                    <UserTypeahead
                      id='user-picker'
                      aria-labelledby='user-label'
                      value={data.user}
                      onChange={newUser =>
                        setData({ ...data, user: newUser?.user })
                      }
                    />
                    <Space h='16px' />
                  </>
                )}
              </WithCommentContent>
            )}
          </Popover2>
        )}
        <Tooltip id='reassign-tooltip' place='bottom'>
          <ToolTipContent>
            {' '}
            <Trans id='pagesrunner.workflow.reassign.another' />
          </ToolTipContent>
        </Tooltip>
        {canResendApprovalEmailOrReassign && (
          <TooltipTrigger
            as={Button}
            small
            mr={2}
            tooltipId='resend-tooltip'
            disabled={processing}
            onClick={async () => {
              if (!processing) {
                setProcessing('resend')
                try {
                  await new Promise(resolve => setTimeout(resolve, 1000))
                  await resendApproval(
                    { ...step, appId: applicationId, documentId },
                    updateOverview
                  )
                  alerts.type3(
                    i18n._('pagesrunner.action.message.sent'),
                    'success'
                  )
                } catch (error) {
                  alerts.type3(error.message, 'error')
                } finally {
                  setProcessing(false)
                }
              }
            }}
          >
            {processing === 'resend' ? (
              <Spinner size={20} />
            ) : (
              `${i18n._('pagesrunner.action.resend')}`
            )}
          </TooltipTrigger>
        )}
        <Tooltip id='resend-tooltip' place='bottom'>
          <ToolTipContent>
            {timeSinceLastResend !== null ? (
              <Trans
                id='pagesrunner.action.last.resent'
                values={{ time: timeSinceLastResend }}
              />
            ) : (
              `${i18n._('pagesrunner.action.send.additional')}`
            )}
          </ToolTipContent>
        </Tooltip>
        <TooltipTrigger
          as={Button}
          disabled={processing}
          small
          tooltipId='view-tooltip'
          forwardedProps={{ as: 'a' }}
          href={`${window.location.origin}${trueUrl}?showStep=${step.stepDefinitionId}`}
          rel='noopener noreferrer'
          target='_blank'
          mr={2}
        >
          <Trans id='pagesrunner.workflow.view.step' />
        </TooltipTrigger>
        <Tooltip id='view-tooltip' place='bottom'>
          <ToolTipContent>
            <Trans id='pagesrunner.workflow.view.step.configuration' />
          </ToolTipContent>
        </Tooltip>
        {retryStepError && <ErrorMsg>{retryStepError.message}</ErrorMsg>}
        {skipStepError && <ErrorMsg>{skipStepError.message}</ErrorMsg>}
        {reassignStepError && <ErrorMsg>{reassignStepError.message}</ErrorMsg>}
        {resendApprovalError && (
          <ErrorMsg>{resendApprovalError.message}</ErrorMsg>
        )}
      </div>
    )
  )
}

const calculateLastResentTime = (step, workflowResends = []) => {
  const lastResend = findLast(workflowResends, wr => wr.stepId === step.id)
  if (lastResend) {
    return formatDistanceToNow(new Date(lastResend.date))
  }
  return null
}

const UserActionsOld = ({ hasError, users = [], hideComments }) => {
  if (!hasError && !users.length) {
    return null
  }
  return (
    <ul>
      {users.map((user, i) => {
        const comment = get(user, 'workflowAction.comment')
        const _date = get(user, 'workflowAction.date')
        const date = _date
          ? new Intl.DateTimeFormat(window.navigator.language, {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric'
            }).format(+new Date(_date))
          : null
        const action = get(user, 'workflowAction.action')
        return (
          <li key={`${get(user, 'id')}-${i}`}>
            <div className='flex items-center font-medium text-medium-gray-500'>
              <Status.Icon status={action} />
              <div>
                <UserActionLabel user={user} />
                {!comment && date && <span> - {date}</span>}
              </div>
            </div>
            {comment && !hideComments && (
              <Comment date={date}>{comment}</Comment>
            )}
          </li>
        )
      })}
    </ul>
  )
}

function UserActionLabel ({ user }) {
  const action = get(user, 'workflowAction.action')
  const by = get(user, 'displayName') || get(user, 'email')
  switch (lowerCase(action)) {
    case 'skipped': {
      return (
        <Trans
          id='pagesrunner.action.workflow.tracker.user.actions.skipped'
          values={{ by }}
        />
      )
    }
    case 'retried': {
      return (
        <Trans
          id='pagesrunner.action.workflow.tracker.user.actions.retried'
          values={{ by }}
        />
      )
    }
    case 'reassigned': {
      const to = get(user, 'workflowAction.reassignTo.displayName')
      return (
        <Trans
          id='pagesrunner.action.workflow.tracker.user.actions.reassigned'
          values={{ by, to }}
        />
      )
    }
    default: {
      return by
    }
  }
}

function SignatureInfoOld ({ signatures }) {
  if (signatures.length) {
    return (
      <div>
        {signatures.map((signature, i) => {
          return (
            <div
              className='flex font-medium text-medium-gray-500'
              key={`signature-${i}`}
            >
              <PenIcon className='ml-px mr-1.5' />
              <div>
                <div className='pb-1 text-blue-500'>
                  <Trans id='pagesrunner.workflow.signed' />{' '}
                  {signature.displayName}
                </div>
                <div>
                  {signature.date &&
                    new Intl.DateTimeFormat(window.navigator.language, {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric'
                    }).format(+new Date(signature.date))}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  } else {
    return null
  }
}

function DuplicateInfo ({ duplicateActions }) {
  if (duplicateActions.length) {
    return (
      <div className='pb-4 pt-4'>
        <div className='text-sm'>
          <span className='font-bold'>
            <Trans id='pagesrunner.workflow.note' />
          </span>
          <span>
            <Trans id='pagesrunner.workflow.attempted.actions' />
          </span>
        </div>
        <ul className='list-inside list-disc'>
          {duplicateActions.map((da, index) => {
            const date = da.date
              ? new Intl.DateTimeFormat(window.navigator.language, {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric'
                }).format(+new Date(da.date))
              : ''
            let description
            if (da.callbackId === 'integration') {
              description = `${i18n._(
                'pagesrunner.workflow.response.integration'
              )}`
            } else if (da.callbackId === 'pdf') {
              description = `${i18n._(
                'pagesrunner.workflow.generation.service'
              )}`
            } else {
              description = `${i18n._('pagesrunner.workflow.handling.attempt', {
                action: da.action,
                user: da.user
              })}`
            }
            return (
              <li key={index} className='text-sm'>
                {description}{' '}
                <Trans
                  id='pagesrunner.workflow.on'
                  comment='this is for the pretranslated description on a date. format is: description on date'
                />{' '}
                {date}.
              </li>
            )
          })}
        </ul>
      </div>
    )
  } else {
    return null
  }
}
