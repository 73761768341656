/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import cx from 'clsx'
import { lowerCase } from 'lodash'
import React from 'react'

import * as Icons from '../../../icons'

const colors = {
  incomplete: 'orange',
  sentback: 'orange',
  complete: '#3db14f',
  error: '#d22e2f',
  sent: '#2b99da',
  default: '#7d8289',

  // incomplete: '#ddd',
  acknowledged: '#3db14f',
  approve: '#3db14f',
  approved: '#3db14f',
  cancelled: '#aaa',
  'cancelled sentback': '#aaa',
  'cancelled rejected': '#aaa',
  completed: '#3db14f',
  denied: '#d22e2f',
  deny: '#d22e2f',
  notified: '#2b99da',
  pending: '#ddd',
  reassigned: 'orange',
  rejected: '#d22e2f',
  retried: 'orange',
  skipped: 'orange',
  submitted: '#3db14f',
  sendback: 'orange',
  'sent back': 'orange',
  'task completed': '#3db14f'
}
const getColor = status => colors[lowerCase(status)] || colors.default

const icons = {
  acknowledged: Icons.WorkflowCheck,
  approve: Icons.WorkflowCheck,
  approved: Icons.WorkflowCheck,
  cancelled: Icons.WorkflowSkipped,
  'cancelled sentback': Icons.WorkflowBack,
  'cancelled rejected': Icons.WorkflowX,
  complete: Icons.WorkflowCheck,
  completed: Icons.WorkflowCheck,
  deny: Icons.WorkflowX,
  error: Icons.WorkflowError,
  notified: Icons.WorkflowNotified,
  reassigned: Icons.WorkflowSkipped,
  rejected: Icons.WorkflowX,
  retried: Icons.WorkflowRetry,
  skipped: Icons.WorkflowSkipped,
  submitted: Icons.WorkflowCheck,
  sendback: Icons.WorkflowBack,
  'sent back': Icons.WorkflowBack,
  'task completed': Icons.WorkflowCheck
}
const getIcon = status => icons[lowerCase(status)] || Empty

const Empty = ({ className, ...props }) => (
  <i className={cx(className, 'border border-light-gray-400')} {...props} />
)

export function Icon ({ status }) {
  const Component = getIcon(status)
  const fill = getColor(status)
  return (
    <Component
      className={cx(
        fill === '#d22e2f' && 'fill-red-400',
        'mr-[7px] flex h-4 w-4 items-center justify-center rounded-[20px]'
      )}
      aria-label={`${status} ${i18n._('pagesrunner.workflow.tracker.status')}`}
      role='img'
      style={fill === '#d22e2f' ? {} : { fill }}
    />
  )
}

export function Message ({ status, children }) {
  const color = getColor(status)
  return (
    <span
      style={color === '#d22e2f' ? {} : { color }}
      className={cx(color === '#d22e2f' && 'text-red-400')}
    >
      {children}
    </span>
  )
}
